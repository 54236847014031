import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { AboutWrapper } from "../components/Section"

import { Nodata, NodataTitle } from "../components/Nodata"

const ProductPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <SEO title="404 - Not found" />
      <AboutWrapper>
        <div className="container">
          <Nodata>
            <NodataTitle className="h2">Page Not Found</NodataTitle>
          </Nodata>
        </div>
      </AboutWrapper>
    </Layout>
  )
}

export default ProductPage
